import { frontendURL } from '../../helper/URLHelper';
import { routes as searchRoutes } from '../../modules/search/search.routes';
import { routes as contactRoutes } from './contacts/routes';
import conversation from './conversation/conversation.routes';
import helpcenterRoutes from './helpcenter/helpcenter.routes';
import { routes as inboxRoutes } from './inbox/routes';
import { routes as notificationRoutes } from './notifications/routes';
import settings from './settings/settings.routes';

const AppContainer = () => import('./Dashboard.vue');
const Suspended = () => import('./suspended/Index.vue');

export default {
    routes: [
        ...helpcenterRoutes.routes,
        {
            path: frontendURL('accounts/:account_id'),
            component: AppContainer,
            children: [
                ...inboxRoutes,
                ...conversation.routes,
                ...settings.routes,
                ...contactRoutes,
                ...searchRoutes,
                ...notificationRoutes,
            ],
        },
        {
            path: frontendURL('accounts/:accountId/suspended'),
            name: 'account_suspended',
            roles: ['administrator', 'agent', 'restricted_agent'],
            component: Suspended,
        },
    ],
};
