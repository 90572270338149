import VueRouter from 'vue-router';

import AnalyticsHelper from '../helper/AnalyticsHelper';
import { validateLoggedInRoutes } from '../helper/routeHelpers';
import { frontendURL } from '../helper/URLHelper';
import store from '../store';
import dashboard from './dashboard/dashboard.routes';

const routes = [...dashboard.routes];

window.roleWiseRoutes = {
    agent: [],
    administrator: [],
    restricted_agent: [],
};

// generateRoleWiseRoute - updates window object with agent/admin route
const generateRoleWiseRoute = route => {
    route.forEach(element => {
        if (element.children) {
            generateRoleWiseRoute(element.children);
        }
        if (element.roles) {
            element.roles.forEach(roleEl => {
                window.roleWiseRoutes[roleEl].push(element.name);
            });
        }
    });
};
// Create a object of routes
// accessible by each role.
// returns an object with roles as keys and routeArr as values
generateRoleWiseRoute(routes);

export const router = new VueRouter({ mode: 'history', routes });

export const validateAuthenticateRoutePermission = (to, next, { getters }) => {
    const { isLoggedIn, getCurrentUser: user } = getters;

    if (!isLoggedIn) {
        window.location = '/app/login';
        return '/app/login';
    }

    if (!to.name) {
        return next(frontendURL(`accounts/${user.account_id}/dashboard`));
    }

    const nextRoute = validateLoggedInRoutes(
        to,
        getters.getCurrentUser,
        window.roleWiseRoutes
    );
    return nextRoute ? next(frontendURL(nextRoute)) : next();
};

export const initalizeRouter = () => {
    const userAuthentication = store.dispatch('setUser');

    router.beforeEach((to, from, next) => {
        AnalyticsHelper.page(to.name || '', {
            path: to.path,
            name: to.name,
        });

        userAuthentication.then(() => {
            return validateAuthenticateRoutePermission(to, next, store);
        });
    });
};

export default router;
